/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Constants
 *
 * INIT_IFRAME
 * Description: Represents the action type for initializing an iframe.
 * Value: 'INIT_IFRAME'
 *
 * SET_COOKIE
 * Description: Represents the action type for setting a cookie.
 * Value: 'SET_COOKIE'
 *
 * SET_END_USER_ATTRIBUTE
 * Description: Represents the action type for setting an end user attribute.
 * Value: 'SET_END_USER_ATTRIBUTE'
 *
 * CHANGE_CONTAINER_CLASS
 * Description: Represents the action type for changing the container class.
 * Value: 'CHANGE_CONTAINER_CLASS'
 *
 * CHANGE_CONTAINER_CLASS_DONE
 * Description: Represents the action type for indicating the completion of changing the container class.
 * Value: 'CHANGE_CONTAINER_CLASS_DONE'
 *
 * SET_DEBUG_MODE
 * Description: Represents the action type for setting debug mode.
 * Value: 'SET_DEBUG_MODE'
 *
 * DOMAIN_NOT_ALLOWED
 * Description: Represents the action type for indicating that the domain is not allowed.
 * Value: 'DOMAIN_NOT_ALLOWED'
 *
 * BOOTSTRAP_DONE
 * Description: Represents the action type for indicating the completion of the bootstrap process.
 * Value: 'BOOTSTRAP_DONE'
 */

export const INIT_IFRAME = 'INIT_IFRAME';
export const SET_COOKIE = 'SET_COOKIE';

export const SET_END_USER_ATTRIBUTE = 'SET_END_USER_ATTRIBUTE';

export const CHANGE_CONTAINER_CLASS = 'CHANGE_CONTAINER_CLASS';
export const CHANGE_CONTAINER_CLASS_DONE = 'CHANGE_CONTAINER_CLASS_DONE';

export const SET_DEBUG_MODE = 'SET_DEBUG_MODE';

export const DOMAIN_NOT_ALLOWED = 'DOMAIN_NOT_ALLOWED';

export const BOOTSTRAP_DONE = 'BOOTSTRAP_DONE';
