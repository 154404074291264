import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './en.json';
import french from './fr.json';
import arabic from './rs.json';
const resources = {
    en: {
        translation: english,
    },
    fr: {
        translation: french,
    },
    rs: {
        translation: arabic,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('lang'),
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
