/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SelectService Component
 * Description: This component allows users to select a service or package from a list of available services or packages. It fetches and displays the services and packages based on the selected business ID. Users can choose a service or package, and their information is passed to the parent component using callback functions.
 *
 * Props:
 * - businessId: any - The ID of the business.
 * - onSelect: (service: IService, is_package?: boolean) => void - Callback function triggered when a service or package is selected.
 * - close?: any - Callback function to close the component.
 *
 */

import { useEffect, useState } from 'react';
import { publicApi } from '../../../helpers/public-axios';
import { IService } from 'interfaces/service.interface';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ThreeCircles } from 'react-loader-spinner';
import moment from 'moment-timezone';

const SelectService = ({
    businessId,
    onSelect,
    close,
}: {
    businessId: any;
    onSelect(service: IService, is_package?: boolean): void;
    close?: any;
}) => {
    const { t }: any = useTranslation();
    const [services, setServices] = useState<IService[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState('service');
    const [packages, setPackages] = useState<any[]>([]);

    const handleTabClick = (tab: any) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        getServices();
        getPackagesService();
    }, [selectedTab]);

    const getServices = async () => {
        setLoading(true);
        await publicApi
            .get(`/businesses/public/${businessId._id}/services`)
            .then(res => {
                setLoading(false);
                setServices(res.data);
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const getPackagesService = async () => {
        setLoading(true);
        await publicApi
            .get(`/package-service?available_for_boooking=${true}`)
            .then(res => {
                setLoading(false);
                setPackages(res.data);
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const formatTime = (hours: any) => {
        const duration = moment.duration(hours, 'minutes');
        const formattedTime = [];

        if (duration.hours() > 0) {
            formattedTime.push(`${duration.hours()} ${duration.hours() === 1 ? 'hour' : 'hours'}`);
        }

        if (duration.minutes() > 0) {
            formattedTime.push(`${duration.minutes()} ${duration.minutes() === 1 ? 'min' : 'min'}`);
        }

        return formattedTime.join(' ');
    };

    return (
        <div className="booking-wrapper">
            {businessId?.active === true ? (
                <>
                    <div className="boking-widget--header">
                        <h3>
                            {selectedTab === 'service' ? 'Select a Service' : 'Select a Package'}
                        </h3>
                    </div>
                    {packages?.length ? (
                        <div className="booking-widget--tabs">
                            <div
                                className={`tab ${selectedTab === 'service' ? 'active' : ''}`}
                                onClick={() => handleTabClick('service')}>
                                Service
                            </div>
                            <div
                                className={`tab ${selectedTab === 'package' ? 'active' : ''}`}
                                onClick={() => handleTabClick('package')}>
                                Package
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {selectedTab === 'service' ? (
                        <div className="booking-widget--body">
                            {loading && (
                                <ThreeCircles
                                    visible={true}
                                    height="70"
                                    width="70"
                                    color="#092C4C"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="loader-style"
                                />
                            )}
                            <CustomDiv>
                                {services?.length
                                    ? services.map((service, i) =>
                                          service.available_for_boooking == true ? (
                                              <div
                                                  className="single-service"
                                                  style={{
                                                      background:
                                                          i % 2 === 0
                                                              ? 'rgb(230, 227, 227, 0.5)'
                                                              : '',
                                                      padding: '20px 0.5rem',
                                                      borderRadius: '0.5rem',
                                                      border: 'none',
                                                  }}>
                                                  <div>
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              gap: '1rem',
                                                              alignItems: 'center',
                                                          }}>
                                                          <div className="service-title">
                                                              {service.name}
                                                          </div>
                                                          {service?.is_video && (
                                                              <i className="fal fa-video" />
                                                          )}
                                                      </div>

                                                      <div className="service-duration">{`${
                                                          service.duration >= 60
                                                              ? Math.floor(service.duration / 60) +
                                                                t('h')
                                                              : ''
                                                      } ${service.duration % 60}${t('min')}`}</div>
                                                  </div>

                                                  <div className="service--bookWrapper">
                                                      {businessId.hide_business_prices === true ? (
                                                          ''
                                                      ) : (
                                                          <div className="service-price">
                                                              {businessId.currency}
                                                              {service.price}
                                                          </div>
                                                      )}
                                                      <button
                                                          className="btn select-serviceBtn"
                                                          onClick={() => onSelect(service)}>
                                                          {t('Select')}
                                                      </button>
                                                  </div>
                                              </div>
                                          ) : (
                                              services?.length == 0 && (
                                                  <div className="selectDate">
                                                      {' '}
                                                      <span className="text">
                                                          {t('No service found')}{' '}
                                                      </span>
                                                  </div>
                                              )
                                          )
                                      )
                                    : services?.length == 0 && (
                                          <div className="selectDate">
                                              {' '}
                                              <span className="text">{t('No service found')} </span>
                                          </div>
                                      )}
                            </CustomDiv>
                        </div>
                    ) : (
                        <div className="booking-widget--body">
                            {loading && (
                                <ThreeCircles
                                    visible={true}
                                    height="70"
                                    width="70"
                                    color="#092C4C"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="loader-style"
                                />
                            )}
                            <CustomDiv>
                                {packages?.length
                                    ? packages.map((service: any, i) => (
                                          <div
                                              className="single-service"
                                              style={{
                                                  background:
                                                      i % 2 === 0 ? 'rgb(230, 227, 227, 0.5)' : '',
                                                  padding: '20px 0.5rem',
                                                  borderRadius: '0.5rem',
                                                  border: 'none',
                                              }}>
                                              <div>
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          gap: '1rem',
                                                          alignItems: 'center',
                                                          marginBottom: '0.3rem',
                                                      }}>
                                                      <div className="service-title">
                                                          {service.name}
                                                      </div>
                                                  </div>

                                                  {service?.services?.map(
                                                      (e: any, index: number, array: any[]) => (
                                                          <div
                                                              key={e.id}
                                                              style={{
                                                                  alignItems: 'center',
                                                                  display: 'flex',
                                                                  gap: '0.1rem',
                                                              }}>
                                                              {e.name}
                                                              <span
                                                                  style={{
                                                                      opacity: '0.8',
                                                                      fontSize: '0.8rem',
                                                                  }}>{` ( ${t('price')}: ${
                                                                  businessId.currency + e?.price
                                                              }, ${t('duration')}: ${formatTime(
                                                                  e?.duration
                                                              )} )`}</span>
                                                              {e?.is_video && (
                                                                  <i className="fal fa-video" />
                                                              )}
                                                              {index < array.length - 1 && <br />}
                                                          </div>
                                                      )
                                                  )}
                                                  {service?.discount ? (
                                                      <div
                                                          className="service-duration"
                                                          style={{ marginTop: '0.3rem' }}>
                                                          {t('Discount')}: {service?.discount + '%'}
                                                      </div>
                                                  ) : (
                                                      ''
                                                  )}
                                                  <div
                                                      className="service-duration"
                                                      style={{ marginTop: '0.2rem' }}>
                                                      {t('Duration')}:
                                                      {`${formatTime(
                                                          service?.services?.reduce(
                                                              (a: any, b: any) =>
                                                                  a + parseInt(b?.duration),
                                                              0
                                                          )
                                                      )}`}
                                                  </div>
                                              </div>

                                              <div className="service--bookWrapper">
                                                  {businessId.hide_business_prices === true ? (
                                                      ''
                                                  ) : (
                                                      <div className="service-price">
                                                          {businessId.currency}
                                                          {service.price}
                                                      </div>
                                                  )}
                                                  <button
                                                      className="btn select-serviceBtn"
                                                      onClick={() => onSelect(service, true)}>
                                                      {t('Select')}
                                                  </button>
                                              </div>
                                          </div>
                                      ))
                                    : packages?.length == 0 && (
                                          <div className="selectDate">
                                              {' '}
                                              <span className="text">{t('No service found')} </span>
                                          </div>
                                      )}
                            </CustomDiv>
                        </div>
                    )}
                </>
            ) : (
                <BlockUser className="booking-widget--body">
                    <div
                        style={{
                            marginBottom: '1rem',
                        }}>
                        {t('User Blocked, Please contact admin for furthur information!')}
                    </div>
                    <button
                        onClick={close}
                        className="btn-book"
                        style={{
                            backgroundColor: `${
                                businessId?.booking_widget?.button_bg_color
                                    ? businessId?.booking_widget?.button_bg_color
                                    : '#092C4C'
                            }`,
                            color: `
                    ${
                        businessId?.booking_widget?.button_text_color
                            ? businessId?.booking_widget?.button_text_color
                            : '#ffff'
                    }`,
                        }}>
                        {t('Close')}
                    </button>
                </BlockUser>
            )}
        </div>
    );
};

const CustomDiv = styled.div`
    padding: 0 16px 2px 16px;
`;

const BlockUser = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
`;
export default SelectService;
