/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * InfoModel Component
 * Description: This component represents a model displaying appointment terms.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the model is closed.
 */

import { useTranslation } from 'react-i18next';
import { Container, media, Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';

const InfoModel = ({ onClose }: { onClose(): void }) => {
    const { t }: any = useTranslation();
    return (
        <Container>
            <Wrapper>
                <ModelBody>
                    <ModelClose></ModelClose>
                    <h3>
                        <b>{t('Appointment Terms')}</b>
                    </h3>
                    <ModelContent>
                        {t(
                            'If you miss your appointment or cancel less than 2 hours before the start time, your payment method will be charged. By scheduling your appointment, you agree to the Appointment Terms.'
                        )}
                    </ModelContent>{' '}
                    <AgreeButton onClick={onClose}>{t('I Agree')}</AgreeButton>
                </ModelBody>
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled(Row)`
    position: fixed;
    top: 0;
    left: 1.1rem;
    border-radius: 0.98rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem ${palette.grey.shadow};
    padding: 1.7rem;
    max-width: 35vw;
    ${media.xs`
        width: 100%;
        max-width: 100%;
    `}
    ${media.sm`
        width: 100%;
        max-width: 60vw;
    `}
    ${media.md`
        width: 100%;
        max-width: 45vw;
    `}
    ${media.lg`
        max-width: 35vw;
    `}
`;

const ModelClose = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;

const AgreeButton = styled.button`
    padding-left: 25px;
    padding-right: 25px;
    height: 2.4rem;
    color: #fff;
    background-color: #000;
    border-radius: 4px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
`;

const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem !important;
    padding-bottom: 2.5rem !important;
    color: #000;
    font-size: 1rem;
`;
export default InfoModel;
