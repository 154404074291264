/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CompleteBooking Component
 * Description: This component represents the final step in the booking process, allowing users to complete their booking request. It includes a credit card form, payment handling, and options for reservation with or without payment. Terms and conditions are also presented for customer agreement.
 *
 * Props:
 * - service: any - Information about the selected service.
 * - stripeAccountId: string - The Stripe account ID for payment processing.
 * - onPrevious: () => void - Callback function triggered when navigating to the previous step.
 * - onNext: (paymentMethodId: string) => void - Callback function triggered when moving to the next step with a payment method ID.
 * - businessId: any - Business-related information.
 * - buttonTitle: any - The title for the booking button.
 * - loading: any - Loading state during the booking process.
 * - onReserve: (id: any) => void - Callback function triggered when reserving without payment.
 * - reserve: any - Flag indicating whether to reserve without payment.
 * - customer?: any - Customer information.
 * - errorMessage?: any - Error message during the booking process.
 *
 */

import { useState, useCallback } from 'react';
import leftChevron from '../../../images/chevron-left.svg';
import { publicApi } from '../../../helpers/public-axios';
import styled from 'styled-components';
import NewCreditCard from './creditCard';
import TermsModel from './TermsModel';
import { useTranslation } from 'react-i18next';
import { ThreeCircles } from 'react-loader-spinner';

const CompleteBooking = ({
    service,
    stripeAccountId,
    onPrevious,
    onNext,
    businessId,
    buttonTitle,
    loading,
    reserve,
    onReserve,
    customer,
    errorMessage,
}: {
    service: any;
    stripeAccountId: string;
    onPrevious(): void;
    onNext(paymentMethodId: string): void;
    businessId: any;
    buttonTitle: any;
    loading: any;
    onReserve(id: any): void;
    reserve: any;
    customer?: any;
    errorMessage?: any;
}) => {
    const [cardState, setCardState] = useState({
        cardNumber: '',
        cardHolder: 'FULL NAME',
        cardMonth: '',
        cardYear: '',
        cardCVV: '',
        isCardFlipped: false,
    });
    const { t }: any = useTranslation();
    const [loadingSubmit, setLoading] = useState<boolean>(false);
    const [onCardResponse, setOnCardResponse] = useState<boolean>(false);
    const [cardNumber, setCardNumber] = useState<any>('');
    const [withoutPayment, setWithoutPayment] = useState<boolean>(false);
    const [termsModel, setTermsModel] = useState<boolean>(true);

    const updateCardState = useCallback(
        (keyName: string, value: any) => {
            setCardState({
                ...cardState,
                [keyName]: value,
            });
        },
        [cardState]
    );

    const createPaymentMethod = useCallback(() => {
        setLoading(true);
        publicApi
            .post('/billing/payment-method', {
                stripe_account_id: stripeAccountId,
                number: cardState.cardNumber,
                exp_month: cardState.cardMonth,
                exp_year: cardState.cardYear,
                cvc: cardState.cardCVV,
                customer_email: customer,
            })
            .then(res => {
                if (withoutPayment == true) {
                    return;
                } else {
                    onNext(res.data as any);
                }
                setCardNumber('');
            })
            .catch((e: any) => {
                console.log('res', e);
                setWithoutPayment(false);
                if (e?.response) {
                    setCardNumber(e.response.data.message);
                }
                setLoading(false);
                setOnCardResponse(true);
            });
    }, [cardState]);

    const createPaymentMethodonReserve = useCallback(() => {
        publicApi
            .post('/billing/payment-method', {
                stripe_account_id: stripeAccountId,
                number: cardState.cardNumber,
                exp_month: cardState.cardMonth,
                exp_year: cardState.cardYear,
                cvc: cardState.cardCVV,
                customer_email: customer,
            })
            .then((res: any) => {
                onReserve(res.data.id);
                setCardNumber('');
            })
            .catch((e: any) => {
                console.log('res', e);
                if (e?.response) {
                    setCardNumber(e.response.data.message);
                }
                setWithoutPayment(false);
                setLoading(false);
                setOnCardResponse(true);
            });
    }, [cardState]);

    return (
        <div className="booking-wrapper">
            {loadingSubmit === true || loading === true ? (
                <ThreeCircles
                    visible={true}
                    height="70"
                    width="70"
                    color="#092C4C"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{}}
                    wrapperClass="loader-style"
                />
            ) : (
                ''
            )}
            <div className="boking-widget--card-header">
                {loadingSubmit === true || loading === true ? (
                    <div className="booking-widget--backBtn" style={{ opacity: '0' }}>
                        <img src={leftChevron} alt="" />
                    </div>
                ) : (
                    <div className="booking-widget--backBtn" onClick={onPrevious}>
                        <img src={leftChevron} alt="" />
                    </div>
                )}
                <h3>{t('Complete Booking Request')}</h3>
            </div>
            <CustomDiv>
                <NewCreditCard
                    initialState={cardState}
                    loading={loadingSubmit}
                    onChange={updateCardState}
                />
                {cardState.cardCVV?.length < 5 &&
                cardState.cardHolder &&
                cardState.cardMonth &&
                cardState.cardNumber?.length >= 12 &&
                cardState.cardYear ? (
                    <>
                        <div className="carddetail--footer" style={{ flexDirection: 'column' }}>
                            {(loading === true || onCardResponse === true) && cardNumber && (
                                <div className="card_error">
                                    {' '}
                                    <span className="error-text">{cardNumber}</span>
                                </div>
                            )}
                            {errorMessage && (
                                <div className="card_error">
                                    {' '}
                                    <span className="error-text">{errorMessage}</span>
                                </div>
                            )}
                            <div>
                                <div
                                    className="carddetail--footer"
                                    style={{ flexDirection: 'column', border: 'none' }}>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            margin: '1rem 0rem',
                                            color: 'black',
                                            borderRadius: '4px',
                                            padding: '10px 45px',
                                        }}>
                                        {buttonTitle}
                                    </div>
                                    <button className="btn" onClick={createPaymentMethod}>
                                        {loadingSubmit === false || loading === true
                                            ? t('Reserve & Pay')
                                            : t('Please wait...')}{' '}
                                        {businessId.currency}
                                        {parseFloat(service).toFixed(2)}
                                    </button>
                                    <button
                                        className="btn"
                                        style={{ marginTop: '0.5rem' }}
                                        onClick={() => {
                                            setWithoutPayment(true);
                                            createPaymentMethodonReserve();
                                        }}>
                                        {reserve === false ? t('Reserve') : t('Please wait...')}{' '}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="agreement-txt-card">
                            <Terms onClick={() => setTermsModel(true)}>
                                {t('By booking an appointment you agree to the')}{' '}
                                <a href="#" target="_blank">
                                    {t('Privacy Policy')}
                                </a>{' '}
                                {t('and')}{' '}
                                <a href="#" target="_blank">
                                    {t('Terms of Use')}
                                </a>
                                .
                            </Terms>
                        </div>
                    </>
                ) : (
                    <div>
                        <div
                            className="carddetail--footer-disabled"
                            style={{ flexDirection: 'column' }}>
                            <div
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    margin: '1rem 0rem',
                                    color: 'black',
                                    padding: '10px 45px',
                                }}>
                                {buttonTitle}
                            </div>
                            <button className="btn">
                                {loadingSubmit === false || loading === true
                                    ? t('Reserve & Pay')
                                    : t('Please wait...')}{' '}
                                {businessId.currency}
                                {parseFloat(service).toFixed(2)}
                            </button>
                            <button className="btn" style={{ marginTop: '0.5rem' }}>
                                {reserve === false ? t('Reserve') : t('Please wait...')}{' '}
                            </button>
                        </div>
                        <div className="agreement-txt-card">
                            <Terms>
                                {t('By booking an appointment you agree to the')}{' '}
                                <a href="#" target="_blank">
                                    {t('Privacy Policy')}
                                </a>{' '}
                                {t('and')}{' '}
                                <a href="#" target="_blank">
                                    {t('Terms of Use')}
                                </a>
                                .
                            </Terms>
                        </div>
                    </div>
                )}
                {termsModel === true && <TermsModel onClose={() => setTermsModel(false)} />}
            </CustomDiv>
        </div>
    );
};
const CustomDiv = styled.div`
    padding: 10px 16px 2px 16px;
    height: 100vh;
    overflow-y: auto;
`;

const Terms = styled.div`
    cursor: pointer;
`;
export default CompleteBooking;
