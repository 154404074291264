/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * StaffCard Component
 * Description: This component represents a card for displaying information about a staff member in the booking system widget.
 *
 * Props:
 * - singleStaff: object - Information about a single staff member.
 * - selectedStaff: object - Information about the currently selected staff member.
 * - setSelectedStaff: (staff: object) => void - Callback function triggered when a staff member is selected.
 * - my_duration: (duration: number) => void - Callback function triggered when the duration is set.
 * - my_price: (price: number) => void - Callback function triggered when the price is set.
 * - vip_price: (price: number) => void - Callback function triggered when the VIP price is set.
 * - businessId: any - Information about the business.
 * - service: object - Information about the service associated with the staff member.
 * - id: string - The ID of the staff member to get online status.
 */

import { publicApi } from '../../../helpers/public-axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StaffCard = ({
    singleStaff,
    selectedStaff,
    setSelectedStaff,
    my_duration,
    my_price,
    vip_price,
    businessId,
    service,
    id,
}: any) => {
    const { t }: any = useTranslation();
    const [availabilityStatus, setAvailabilityStatus] = useState<any[]>([]);
    const [is_available, setIs_available] = useState(false);
    const todayTime = moment().format('HH:mm');

    useEffect(() => {
        if (id) {
            getAvailabilityOfServiceProvider(id);
        }
    }, [id]);

    useEffect(() => {
        if (availabilityStatus?.length) {
            let format = 'hh:mm';
            for (let i = 0; i <= availabilityStatus?.length; i++) {
                let time = moment(todayTime, format),
                    beforeTime = moment(availabilityStatus[i], format),
                    afterTime = moment(availabilityStatus[i + 1], format);

                if (time.isBetween(beforeTime, afterTime)) {
                    setIs_available(true);
                }
            }
        }
    }, [availabilityStatus]);

    const getAvailabilityOfServiceProvider = (id: any) => {
        setIs_available(false);
        if (id !== undefined) {
            let date: any = moment().format('YYYY-MM-DD');
            const time = moment().format('HH:mm:ssZ');
            let selectedDate: any = date + 'T' + time;
            let duration = '15';
            publicApi
                .post(`appointments/availability_status/${id}`, {
                    date: selectedDate,
                    duration: duration,
                })
                .then((res: any) => {
                    setAvailabilityStatus(res.data.slots);
                });
        }
    };

    return (
        <div
            key={singleStaff._id}
            className={`professional-card ${
                singleStaff === selectedStaff ? 'selectedProfessional' : ''
            }`}
            onClick={() => {
                setSelectedStaff(singleStaff);
                my_duration(service.my_duration);
                my_price(service.my_price);
                vip_price(service.vip_price);
            }}>
            <img src={`https://datasetsss.s3.amazonaws.com/${singleStaff?.photo}`} alt="" />
            {is_available === true && <OnlineBadge></OnlineBadge>}
            <div className="professional-name">{singleStaff.name}</div>

            <div className="professional-subText">
                <div className="service-details">
                    <span className="service-direction-title">{t('Duration')}</span>
                    &nbsp; &nbsp;
                    <span>
                        {service.my_duration}
                        {t('min')}
                    </span>
                </div>

                <div className="service-details">
                    <span className="service-direction-title">{t('Price')}</span>
                    &nbsp; &nbsp;
                    <span>
                        {businessId?.currency}
                        {service.my_price}
                    </span>
                </div>
                {singleStaff?.vip == true && (
                    <div className="service-details">
                        <span className="service-direction-title">{t('VIP Price')}</span>
                        &nbsp; &nbsp;
                        <span>
                            {businessId?.currency}
                            {service.vip_price}
                        </span>
                    </div>
                )}
                {singleStaff?.is_waiting == true && (
                    <div className="service-details">
                        <span className="service-direction-title">{t('Waiting list')}</span>
                        &nbsp; &nbsp;
                        <span>{t('Enabled')}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

const OnlineBadge = styled.div`
    width: 1.3rem;
    height: 1.3rem;
    background-color: green;
    border-radius: 50%;
    position: relative;
    right: -1.8rem;
    bottom: 2.7rem;
`;

export default StaffCard;
