/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerDetails Component
 * Description: This component collects and validates customer details such as first name, last name, email, phone number, and appointment notes for appointment. It also includes functionality for navigating to the previous step, submitting the details, and displaying loading indicators.
 *
 * Props:
 * - onPrevious: () => void - Callback function triggered when navigating to the previous step.
 * - onNext: (customer_details: any) => void - Callback function triggered when moving to the next step with customer details.
 * - buttonTitle: any - The title for the button.
 * - customer_note: (val: any) => void - Callback function triggered when the customer notes are updated.
 * - loading?: boolean - Loading state during the submission process.
 * - errorMessage?: any - Error message during the submission process.
 */

import Spinner from '../../common/spinner/Spinner';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import leftChevron from '../../../images/chevron-left.svg';
import Input from '../../common/input/Input';
import { ThreeCircles } from 'react-loader-spinner';

const CustomerDetails = ({
    onPrevious,
    onNext,
    buttonTitle,
    customer_note,
    loading,
    errorMessage,
}: {
    onPrevious(): void;
    onNext(customer_details: any): void;
    buttonTitle: any;
    customer_note(val: any): void;
    loading?: boolean;
    errorMessage?: any;
}) => {
    const { t }: any = useTranslation();
    const [firstName, setFirstName] = useState<any>();
    const [lastName, setLastName] = useState<any>();
    const [email, setEmail] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<any>();

    const data = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phoneNumber,
    };

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const validate = (text: any) => {
        if (reg.test(text) === false) {
            alert(t('You have entered an invalid email address!'));
            setEmail(text);
            return false;
        } else {
            setEmail(text);
        }
    };

    const onSubmit = () => {
        validate(email);
        if (reg.test(email) === false) {
            return null;
        } else {
            onNext(data);
        }
    };

    const phoneValidate = (value: any) => {
        let phone = value;
        if (phone.length <= 3) {
            phone = phone.replace(/[-.]/g, '');
        } else if (phone.length <= 7) {
            phone = phone.replace(/[-.]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
        } else if (phone.length >= 7) {
            phone = phone.replace(/[-.]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
        }
        setPhoneNumber(phone);
    };

    return (
        <div className="booking-wrapper">
            <div className="boking-widget--header">
                <div className="booking-widget--backBtn" onClick={onPrevious}>
                    <img src={leftChevron} alt="" />
                </div>
                <h3>{t('Your Details')}</h3>
            </div>
            <Spinner color="black" />
            <div className="booking-widget--body">
                <div className="booking-widget--body-detail" style={{ paddingTop: '8px' }}>
                    <p
                        style={{
                            paddingLeft: '4px',
                            paddingBottom: '10px',
                            fontSize: '14px',
                            color: '#5e5e5e',
                        }}>
                        {t(
                            'By entering your information below, you agree to receive communication by email and SMS regarding updates.'
                        )}
                    </p>
                    <div className="userDetails-grid userDetails-group">
                        <Input
                            label={t('First Name')}
                            name="firstName"
                            allowNumber={false}
                            onChange={(e: any) => {
                                setFirstName(e.value);
                            }}
                        />
                        <Input
                            label={t('Last Name')}
                            name="lastName"
                            allowNumber={false}
                            onChange={(e: any) => {
                                setLastName(e.value);
                            }}
                        />
                    </div>
                    <div className="userDetails-group">
                        <Input
                            required={true}
                            label={t('Email')}
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e: any) => {
                                setEmail(e.value?.toLowerCase());
                            }}
                        />
                        {email && reg.test(email) === false ? (
                            <div className="validationField">
                                {' '}
                                <span className="error-text">
                                    {t('A valid email is required for updates.')}
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="userDetails-group">
                        <Input
                            allowPhoneNumberOnly={true}
                            maxLength={12}
                            label={t('Phone')}
                            name="phoneNumber"
                            value={phoneNumber}
                            type="tel"
                            inputMode="numeric"
                            onChange={(e: any) => {
                                phoneValidate(e.value);
                            }}
                        />
                    </div>

                    <div className="userDetails-group">
                        <Input
                            label={t('Appointment Notes (Not Required)')}
                            onChange={(e: any) => customer_note(e.value)}
                        />
                    </div>
                </div>
                {errorMessage && (
                    <div className="card_error">
                        {' '}
                        <span className="error-text">{errorMessage}</span>
                    </div>
                )}
            </div>

            {loading && (
                <ThreeCircles
                    visible={true}
                    height="70"
                    width="70"
                    color="#092C4C"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{}}
                    wrapperClass="loader-style"
                />
            )}
            {firstName && lastName && reg.test(email) === true && phoneNumber ? (
                <div className="personaldetail--footer" style={{ position: 'fixed' }}>
                    <button className="btn" onClick={() => onSubmit()}>
                        {loading ? t('Processing please wait...') : buttonTitle}
                    </button>
                </div>
            ) : (
                <div className="personaldetail--footer-disabled" style={{ position: 'fixed' }}>
                    <button className="btn"> {buttonTitle}</button>
                </div>
            )}
        </div>
    );
};
export default CustomerDetails;
