/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Additional API Configurations
 * Description: This file configures additional Axios instances for specific API calls foe widget.
 */

import axios from 'axios';

export const publicApi = axios.create({
    baseURL: `https://api.economicsale.finance/booking/`,
    headers: {
        'Content-Type': 'application/json',
    },
});
export const slotApi = axios.create({
    baseURL: `https://api.economicsale.finance/booking/`,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 1000 * 10, // Wait for 10 seconds
});

publicApi.interceptors.request.use(
    request => {
        var _userData = JSON.parse(localStorage.getItem('access-token') || '{}');
        request.headers.common.Authorization = `Bearer ${_userData}`;

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

publicApi.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);
slotApi.interceptors.request.use(
    request => {
        var _userData = JSON.parse(localStorage.getItem('access-token') || '{}');
        request.headers.common.Authorization = `Bearer ${_userData}`;

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

slotApi.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);
