/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SelectProfessional Component
 * Description: This component allows users to select a professional for a specific service. It fetches and displays a list of professionals who offer the selected service. Users can choose a professional, and their information is passed to the parent component using callback functions.
 *
 * Props:
 * - businessId: any - The ID of the business.
 * - onPrevious: () => void - Callback function triggered when the "Previous" button is clicked.
 * - onSelect: (professional: IServiceProvider) => void - Callback function triggered when a professional is selected and user want to move next screen.
 * - serviceId: any - The ID of the selected service.
 * - my_duration: (myDuration: any) => void - Callback function triggered with the selected professional's service duration.
 * - my_price: (myPrice: any) => void - Callback function triggered with the selected professional's service standard price.
 * - vip_price: (vip: any) => void - Callback function triggered with the selected professional's service VIP price.
 *
 */

import { publicApi } from '../../../helpers/public-axios';
import { useState, useEffect } from 'react';
import leftChevron from '../../../images/chevron-left.svg';
import { IServiceProvider } from '../../../interfaces/serviceProvider.interface';
import { useTranslation } from 'react-i18next';
import StaffCard from './StaffCard';
import { ThreeCircles } from 'react-loader-spinner';

const SelectProfessional = ({
    businessId,
    onPrevious,
    onSelect,
    serviceId,
    my_duration,
    my_price,
    vip_price,
}: {
    businessId: any;
    onPrevious(): void;
    serviceId: any;
    my_duration(myDuration: any): void;
    my_price(myPrice: any): void;
    onSelect(professional: IServiceProvider): void;
    vip_price(vip: any): void;
}) => {
    const { t }: any = useTranslation();
    const [staff, setStaff] = useState<IServiceProvider[]>([]);
    const [selectedStaff, setSelectedStaff] = useState<IServiceProvider>();
    const [filterServices, setFilterservices] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBusinessProfessionals();
    }, []);

    const getBusinessProfessionals = async () => {
        try {
            setLoading(true);
            await publicApi
                .get(`/serviceProvider/public/${businessId._id}/serviceProvider`)
                .then((res: any) => {
                    setLoading(false);
                    setStaff(data(res.data.serviceProvider));
                })
                .catch((e: any) => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    let _filterData: any[] = [];
    const data = (_data: any) => {
        _filterData = [];
        _data.map((provider: any, index: any) => {
            if (provider.services != undefined) {
                provider.services.map((service: any, index: any) => {
                    if (service.service_id == serviceId) {
                        _filterData.push(provider);
                        filterServices.push(provider);
                    }
                });
            }
        });
        return _filterData;
    };

    return (
        <div className="booking-wrapper">
            <div className="boking-widget--header">
                <div className="booking-widget--backBtn" onClick={onPrevious}>
                    <img src={leftChevron} alt="" />
                </div>
                <h3>{t('Select a Professional')}</h3>
            </div>
            <div className="booking-widget--body">
                {loading && (
                    <ThreeCircles
                        visible={true}
                        height="70"
                        width="70"
                        color="#092C4C"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass="loader-style"
                    />
                )}
                <div className="booking-widget--body-professional">
                    <div className="professionals-container">
                        {staff.map((singleStaff: any) =>
                            singleStaff.services.map((service: any) => {
                                return (
                                    <>
                                        {service.service_id == serviceId ? (
                                            <StaffCard
                                                id={singleStaff._id}
                                                singleStaff={singleStaff}
                                                selectedStaff={selectedStaff}
                                                setSelectedStaff={(e: any) => setSelectedStaff(e)}
                                                my_duration={(e: any) => my_duration(e)}
                                                my_price={(e: any) => my_price(e)}
                                                vip_price={(e: any) => vip_price(e)}
                                                service={service}
                                                businessId={businessId}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </>
                                );
                            })
                        )}

                        {filterServices?.length == 0 && (
                            <div className="selectDate">
                                {' '}
                                <span className="text">{t('No Professional Available')} </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {selectedStaff ? (
                <div
                    className="booking-widget--footer"
                    onClick={() => onSelect(selectedStaff)}
                    style={{ position: 'fixed', height: '88px' }}>
                    <button className="btn">{t('Next')}</button>
                </div>
            ) : (
                <div
                    className="booking-widget--footer-disabled"
                    style={{ position: 'fixed', height: '88px' }}>
                    <button className="btn">{t('Next')}</button>
                </div>
            )}
        </div>
    );
};
export default SelectProfessional;
