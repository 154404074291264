/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SelectTip Component
 * Description: This component handles the selection of tips, including predefined tip percentages, custom tip input, and coupon redemption. It calculates the total charges based on the selected tip, service price, and additional charges.
 *
 * Props:
 * - businessId: any - Information about the business.
 * - subTotal: any - The subtotal amount for the selected service.
 * - service: any - Information about the selected service.
 * - onSelect: (tip: any) => void - Callback function triggered when a tip is selected or redeemed.
 * - gratuity: (value: any, totalCharges: any) => void - Callback function triggered when gratuity changes, responsible for updating total charges.
 * - onPrevious: () => void - Callback function triggered when the user goes to the previous step.
 * - buttonTitle: any - The title for the button.
 * - tipLabel: (val: any) => void - Callback function triggered when the tip label changes.
 * - loading: any - Flag indicating whether the component is in a loading state.
 * - onSuccess: any - Flag indicating whether an error occurred during the process.
 * - couponUsed?: any - Callback function triggered when a coupon is used.
 */

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import leftChevron from '../../../images/chevron-left.svg';
import './styles.scss';
import { media } from 'styled-bootstrap-grid';
import Input from '../../common/input/Input';
import { useTranslation } from 'react-i18next';
import { publicApi } from '../../../helpers/public-axios';
import moment from 'moment';

const SelectTip = ({
    businessId,
    subTotal,
    service,
    onSelect,
    gratuity,
    onPrevious,
    buttonTitle,
    tipLabel,
    loading,
    onSuccess,
    couponUsed,
}: {
    businessId: any;
    onSelect(tip: any): void;
    gratuity(value: any, totalCharges: any): void;
    onPrevious(): void;
    subTotal: any;
    buttonTitle: any;
    service: any;
    tipLabel(val: any): void;
    loading: any;
    onSuccess: any;
    couponUsed?: any;
}) => {
    const [selectedGratuity, setSelectedGratuity] = useState<any>(0);
    const [customTip, setCustomTip] = useState<any>(null);
    const [redeemCoupen, setRedeemCoupen] = useState('');
    const [coupenModelData, setCoupenModelData] = useState<any>('');
    const { t }: any = useTranslation();

    useEffect(() => {
        gratuity(selectedGratuity, totalCharges);
    }, [selectedGratuity, customTip]);

    useEffect(() => {
        if (customTip !== undefined || null) {
            customTip !== null && gratuity(customTip, totalCharges);
            customTip !== null && setSelectedGratuity(customTip);
            customTip !== null && tipLabel('custom tip');
        }
    }, [customTip]);

    const gratuityOptions = [
        {
            label: 'No Tip',
            value: 0,
        },
        {
            label: '20%',
            value: subTotal * 0.2,
        },
        {
            label: '25%',
            value: subTotal * 0.25,
        },
        {
            label: '30%',
            value: subTotal * 0.3,
        },
    ];

    const calculateInPercentage = (subTotal / 100) * parseInt(coupenModelData?.percentage);
    const afterPercentageValue = subTotal - calculateInPercentage;
    const totalServicePrice = coupenModelData
        ? coupenModelData?.in_percentage === true
            ? afterPercentageValue
            : coupenModelData && subTotal - coupenModelData?.price
        : subTotal;
    const totalCharges =
        parseFloat(totalServicePrice) + 1 + parseFloat(selectedGratuity ? selectedGratuity : 0);

    // get coupen details
    const submitCoupen = () => {
        publicApi
            .post(`/gift-card/get`, { redeem: redeemCoupen })
            .then((res: any) => {
                setCoupenModelData(res.data);
            })
            .catch((e: any) => {
                if (e?.response) {
                    const response = e?.response?.data?.response;
                    alert(response?.message);
                }
            });
    };

    return (
        <div className="booking-wrapper">
            <div>
                <div className="boking-widget--header">
                    {loading === true ? (
                        <div className="booking-widget--backBtn" style={{ opacity: '0' }}>
                            <img src={leftChevron} alt="" />
                        </div>
                    ) : (
                        <div className="booking-widget--backBtn" onClick={onPrevious}>
                            <img src={leftChevron} alt="" />
                        </div>
                    )}
                    <h3>{t('Charges and Tip')}</h3>
                </div>
                <div className="booking-widget--body">
                    <div className="grid-items">
                        {gratuityOptions.map(option => {
                            return (
                                <div>
                                    {loading === true ? (
                                        <div
                                            key={option.label}
                                            className={`gratuity--tip ${
                                                selectedGratuity === option.value
                                                    ? 'gratuity_tipActive'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                setSelectedGratuity(selectedGratuity);
                                                setCustomTip(0);
                                            }}>
                                            <div>
                                                {businessId?.currency}
                                                {option.value.toFixed(2)}
                                            </div>
                                            <GratuatyLabel>{t(option.label)}</GratuatyLabel>
                                        </div>
                                    ) : (
                                        <div
                                            key={option.label}
                                            className={`gratuity--tip ${
                                                selectedGratuity === option.value
                                                    ? 'gratuity_tipActive'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                tipLabel(option.label);
                                                setSelectedGratuity(option.value);
                                                setCustomTip(null);
                                            }}>
                                            <div>
                                                {businessId?.currency}
                                                {option.value.toFixed(2)}
                                            </div>
                                            <GratuatyLabel>{t(option.label)}</GratuatyLabel>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <InputField>
                        <Input
                            label={t('Custom Tip')}
                            name="Custom Tip"
                            value={customTip}
                            type="tel"
                            inputMode="numeric"
                            step="any"
                            pattern="[0-9]*\.?[0-9]*"
                            onChange={(e: any) => {
                                setCustomTip(e.value);
                            }}
                        />
                    </InputField>

                    <InputField>
                        <Input
                            label={t('Have any discount voucher/coupen ?')}
                            value={redeemCoupen}
                            inputMode="numeric"
                            onChange={(e: any) => {
                                setRedeemCoupen(e.value);
                            }}
                        />
                    </InputField>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '0.5rem',
                        }}>
                        <button
                            className="btn"
                            style={{ width: 'max-content', padding: '10px' }}
                            onClick={() => submitCoupen()}>
                            {'Redeem'}
                        </button>
                    </div>
                    {coupenModelData ? (
                        <TotalPriceContainer style={{ padding: '0.2rem 1rem' }}>
                            <Left></Left>
                            <Right>
                                <SearchItemWrapper>
                                    <TitleText>{t('Name')}</TitleText>
                                    <TitleText fw="light">{coupenModelData.name}</TitleText>
                                </SearchItemWrapper>
                                {coupenModelData?.in_percentage === true ? (
                                    <SearchItemWrapper>
                                        <TitleText>{t('Percentage Value')}</TitleText>
                                        <TitleText fw="light">
                                            {parseInt(coupenModelData?.percentage)}%
                                        </TitleText>
                                    </SearchItemWrapper>
                                ) : (
                                    <SearchItemWrapper>
                                        <TitleText>{t('Price')}</TitleText>
                                        <TitleText fw="light">
                                            {businessId?.currency + coupenModelData?.price}
                                        </TitleText>
                                    </SearchItemWrapper>
                                )}
                                <SearchItemWrapper border={'0'}>
                                    <TitleText>{t('Expiry Date')}</TitleText>
                                    <TitleText fw="light">
                                        {moment(coupenModelData?.expiry_date).format('YYYY-MM-DD')}
                                    </TitleText>
                                </SearchItemWrapper>
                            </Right>
                        </TotalPriceContainer>
                    ) : (
                        ''
                    )}
                    <div style={{ padding: '1rem', height: '100 vh' }} className="subTotal-div">
                        <CustomDiv>
                            <Left></Left>{' '}
                            <Right>
                                <GratuatyContent>
                                    <Items>{t('Service')}</Items>
                                    <Amount>{t('Amount')}</Amount>
                                </GratuatyContent>

                                <BasketItem>
                                    <ItemsName>{service}</ItemsName>
                                    <ItemsAmount>
                                        {businessId?.currency}
                                        {subTotal}
                                    </ItemsAmount>
                                </BasketItem>
                            </Right>
                        </CustomDiv>
                        <TotalPriceContainer>
                            <Left></Left>
                            <Right>
                                <GratuatyContent>
                                    <Gratuaty>{t('Tip')}</Gratuaty>
                                    <Gratuaty>
                                        {businessId?.currency}
                                        {selectedGratuity
                                            ? parseFloat(selectedGratuity).toFixed(2)
                                            : '0.00'}
                                    </Gratuaty>
                                </GratuatyContent>
                                <DiscountWrapper>
                                    <SubTotalContent>{t('Booking Fee')}</SubTotalContent>
                                    <SubTotalContent>{businessId?.currency}1.00</SubTotalContent>
                                </DiscountWrapper>
                                {coupenModelData && (
                                    <DiscountWrapper>
                                        <SubTotalContent>{t('Discount')}</SubTotalContent>
                                        <SubTotalContent>
                                            {coupenModelData?.in_percentage === true
                                                ? coupenModelData?.percentage + '%'
                                                : businessId?.currency + coupenModelData?.price}
                                        </SubTotalContent>
                                    </DiscountWrapper>
                                )}
                                <SubTotalWrapper>
                                    <SubTotalContent>{t('Total Charges')}</SubTotalContent>
                                    <SubTotalContent>
                                        {businessId?.currency}
                                        {totalCharges.toFixed(2)}
                                    </SubTotalContent>
                                </SubTotalWrapper>
                            </Right>
                        </TotalPriceContainer>
                    </div>
                </div>
            </div>
            {selectedGratuity !== undefined ? (
                <div
                    className="booking-widget--footer"
                    style={{ position: 'fixed', flexDirection: 'column' }}>
                    {onSuccess === true && (
                        <div className="validationField" style={{ marginBottom: '0.5rem' }}>
                            {' '}
                            <span className="error-text">
                                {t('Something went wrong, Check your details and try again!')}{' '}
                            </span>
                        </div>
                    )}
                    <button
                        className="btn"
                        style={{ width: 'max-content' }}
                        onClick={() => {
                            if (coupenModelData) {
                                onSelect(redeemCoupen);
                                couponUsed(true);
                            } else {
                                onSelect('');
                            }
                        }}>
                        {buttonTitle}
                    </button>
                </div>
            ) : (
                <div
                    className="booking-widget--footer-disabled"
                    style={{ position: 'fixed', bottom: '28px' }}>
                    <button className="btn" style={{ width: 'max-content' }}>
                        {buttonTitle}
                    </button>
                </div>
            )}
        </div>
    );
};

const Items = styled.div`
    grid-column: span 6 / span 6 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: rgba(157, 160, 164) !important;
`;

const Amount = styled.div`
    grid-column: span 3 / span 3 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-align: right !important;
    color: rgba(157, 160, 164) !important;
`;

const GratuatyLabel = styled.div`
    color: rgba(157, 160, 164);
`;

const BasketItem = styled.ul`
    display: flex !important;
    justify-content: space-between;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    margin-top: 0.5rem;
`;

const ItemsName = styled.div`
    font-size: 0.92rem;
    display: flex;
    align-items: center;
    grid-column: span 6 / span 6;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ItemsAmount = styled.div`
    font-size: 13.8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column: span 3 / span 3;
    text-align: right;
`;

const CustomDiv = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    gap: 0.75rem !important;
`;

const TotalPriceContainer = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    gap: 0.75rem !important;
`;

const Left = styled.div`
    grid-column: span 3 / span 3 !important;
`;

const Right = styled.div`
    grid-column: span 7 / span 7 !important;
    border-top: 1px solid rgba(223, 226, 230);
`;

const SubTotalWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
`;

const SubTotalContent = styled.div`
    font-size: 0.9rem;
`;

const DiscountWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: 0.5rem !important;
`;

const GratuatyContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
`;

const Gratuaty = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;

const InputField = styled.div`
    margin-top: 1rem;
    width: 100%;
    margin-left: 0.5rem;
    ${media.xs`
    width: 100%;
  `}
    ${media.sm`
    width: 100%;
`}
    ${media.md`
  width: 100%;
`}
`;

const SearchItemWrapper = styled.div<any>`
    display: flex;
    align-items: center;
    padding: 5px 0 0px 0px;
    justify-content: space-between;
`;

const TitleText = styled.div<any>`
    display: ${({ flexDirection }) => (flexDirection ? flexDirection : 'flex')};
    justify-content: start;
    font-weight: ${({ fw }) => (fw ? fw : '400')};
    font-size: 0.9rem;
`;

export default SelectTip;
