/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * AppointmentCalendar Component
 * Description: This component represents a calendar view for managing appointments, providing features for creating, modifying, and deleting appointments. It supports navigation between days, weeks, and months, displaying daily, weekly, and monthly appointments. Additionally, it handles Pusher events for real-time updates and fetches appointments based on the selected date range. Appointments are created and modified within the available working hours of service providers.
 *
 * Props:
 * - onPrevious: () => void - Callback function triggered when navigating to the previous view.
 * - onSelect: (
 *    startDate: any,
 *    startTime: any,
 *    is_vip: boolean,
 *    is_waiting?: boolean,
 *    serviceProvide?: any,
 *    availableStaff?: any,
 *    notAvailableStaff?: any
 * ) => void - Callback function triggered when a date and time are selected. The function returns service provider details, checks for VIP or waiting appointments, and provides data on both available and unavailable staff to the parent component.
 * - serviceProvider: any - The ID of the service provider to load available slots of specific service provider of the day.
 * - serviceDuration: any - The duration of the service.
 * - timezone: any - Set default timezone of business for widget component.
 * - business: any - Business-related information.
 * - defaultData: any - Default data for pre-selected date and time of selected.
 * - selectedService?: any - Information about the selected service.
 * - isPackage?: any - Flag indicating whether the appointment is for a package?.
 *
 */

import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { slotApi } from '../../../helpers/public-axios';
import leftChevron from '../../../images/chevron-left.svg';
import styled from 'styled-components';
import InfoModel from './InfoModel';
import WaitingListConfirmationModel from './WaitingListConfirmationModel';
import { useTranslation } from 'react-i18next';
const today: any = new Date();
import { ThreeCircles } from 'react-loader-spinner';
import Switch from '../../common/switch';

const AppointmentCalendar = ({
    onPrevious,
    onSelect,
    serviceProvider,
    serviceDuration,
    timezone,
    business,
    defaultData,
    selectedService,
    isPackage,
}: {
    onPrevious?(): void;
    onSelect(
        startDate: any,
        startTime: any,
        is_vip: boolean,
        is_waiting?: boolean,
        serviceProvide?: any,
        availableStaff?: any,
        notAvailableStaff?: any
    ): void;
    serviceProvider: any;
    serviceDuration: any;
    timezone: any;
    business: any;
    defaultData: any;
    selectedService?: any;
    isPackage?: any;
}) => {
    const { t } = useTranslation();
    const [selectedSlot, setSelectedSlot] = useState<any>();
    const [morning, setMorning] = useState<any[]>([]);
    const [afterNoon, setAfterNoon] = useState<any[]>([]);
    const [evening, setEvening] = useState<any[]>([]);
    const [selectedDay, setSelectedDay] = useState<any>(new Date());
    const [vipAppointment, setVipAppointment] = useState<any>(false);
    const [infoModel, setInfoModel] = useState<boolean>(false);
    const [response, setResponse] = useState('');
    const [appointmentSlots, setAppointmentSlot] = useState<any>();
    const [waitingList, setWaitingList] = useState<any>();
    const [loading, setLoadig] = useState(false);

    useEffect(() => {
        if (defaultData?.startTime) {
            setSelectedDay(defaultData?.selectedDay);
            setVipAppointment(defaultData?.is_vip);
            setSelectedSlot(
                moment(
                    moment().format() + ' ' + defaultData?.startTime,
                    'YYYY-MM-DD HH:mm:ss'
                ).format('HH:mm')
            );
        }
    }, [defaultData]);

    useEffect(() => {
        if (timezone) {
            moment.tz.setDefault(timezone);
        }
    });

    useEffect(() => {
        if (business?.booking_mode && selectedDay && !selectedSlot) {
            getFirstAvailableStaff(mydate);
        } else {
            getAvailableSlot(mydate);
            getVipAvailableSlot(mydate);
        }
    }, [selectedDay, vipAppointment]);

    let currentFullDate = moment().format();

    let currentTime = moment(currentFullDate).format('HH:mm');
    const currentDate = moment(currentFullDate).format('YYYY-MM-DD');

    const getAvailableSlot = async (date: any) => {
        if (serviceProvider?._id !== undefined && selectedDay !== undefined) {
            setLoadig(true);
            const time = moment().format('HH:mm:ssZ');
            let selectedDate: any = date + 'T' + time;
            await slotApi
                .post(`appointments/available_slots/${serviceProvider?._id}`, {
                    date: selectedDate,
                    duration: serviceDuration,
                    calendar: business?.customCalendar ? 'customise' : undefined,
                })
                .then((res: any) => {
                    setLoadig(false);
                    if (res.data !== undefined) {
                        let arr1: any = res.data?.slots;
                        let arr2: any = res.data?.appointmentSlots;
                        let unique = [...new Set(arr2)];
                        setAppointmentSlot(unique.sort());
                        let finalSlots = serviceProvider?.is_waiting ? arr1?.concat(unique) : arr1;
                        finalSlots?.sort();
                        setResponse('');

                        if (vipAppointment == false) {
                            setMorning([]);
                            setAfterNoon([]);
                            setEvening([]);
                            finalSlots?.map((time: any, index: any) => {
                                if (currentDate == date) {
                                    if (time >= currentTime) {
                                        slotList(time);
                                    }
                                } else {
                                    slotList(time);
                                }
                            });
                        }
                    }
                })
                .catch((e: any) => {
                    setLoadig(false);
                    setMorning([]);
                    setAfterNoon([]);
                    setEvening([]);
                    setSelectedSlot('');
                    setResponse(e?.message);
                });
        }
    };

    const getVipAvailableSlot = (date: any) => {
        try {
            if (selectedDay !== undefined && vipAppointment === true) {
                setLoadig(true);
                setMorning([]);
                setAfterNoon([]);
                setEvening([]);
                setSelectedSlot('');
                let vipTime: any[] = [];
                for (let i: any = 0; i < 24; i++) {
                    for (let j = 0; j < 4; j++) {
                        vipTime.push(moment({ hour: i, minute: j * 15 }).format('HH:mm'));
                    }
                }
                let tmpTime: any[] = [];
                vipTime.map((time: any, index: any) => {
                    let customTime = moment(time, 'HH:mm').add(5, 'minute').format('HH:mm');
                    let tmpTime1 = moment(time, 'HH:mm')
                        .add(serviceDuration, 'minute')
                        .format('HH:mm');
                    let format = 'HH:mm';
                    if (
                        appointmentSlots?.includes(time) ||
                        appointmentSlots?.includes(customTime)
                    ) {
                        for (let i = 0; i < appointmentSlots?.length; i++) {
                            let mTime = moment(appointmentSlots[i], format),
                                beforeTime = moment(time, format),
                                afterTime = moment(tmpTime1, format);
                            if (
                                mTime.isBetween(beforeTime, afterTime) &&
                                mTime.isBetween(beforeTime, afterTime)
                            ) {
                                if (tmpTime[index - 1] != -1) {
                                    tmpTime.splice(index - 1, 1);
                                }
                            }
                        }
                    } else {
                        tmpTime.push(time);
                    }
                });

                tmpTime.map(e => {
                    if (currentDate == date) {
                        if (e >= currentTime) {
                            slotList(e);
                        }
                    } else {
                        slotList(e);
                    }
                });
                setLoadig(false);
            }
        } catch (error) {
            setLoadig(false);
        }
    };

    const slotList = (time: any) => {
        if (time < '12:00') {
            setMorning((prevState: any) => {
                return [...prevState, time];
            });
        } else if (time >= '12:00' && time <= '17:00') {
            setAfterNoon((prevState: any) => {
                return [...prevState, time];
            });
        } else if (time > '17:00') {
            setEvening((prevState: any) => {
                return [...prevState, time];
            });
        }
    };

    let selct: any = new Date(selectedDay);
    let month: any = parseInt(selct.getMonth() + 1);
    let day: any = selct.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    let mydate = selct.getFullYear() + '-' + month + '-' + day;
    const time = moment().format('HH:mm:ssZ');
    let selectedDate: any = selectedDay ? mydate + 'T' + time : null;

    const getFirstAvailableStaff = (date: any, selectedTime?: any, isFirstSelect = false) => {
        if (selectedDay !== undefined) {
            setLoadig(true);
            slotApi
                .post(`appointments/available_staff/get`, {
                    date: date,
                    duration: selectedService?.duration,
                    calendar: business?.customCalendar ? 'customise' : undefined,
                    serviceId: selectedService?._id,
                    selectedTime: selectedTime,
                    isFirstSelect: isFirstSelect,
                    isPackage: isPackage,
                })
                .then((res: any) => {
                    setLoadig(false);
                    if (res.data !== undefined) {
                        if (isFirstSelect) {
                            if (isPackage) {
                                if (res.data?.staff?.length) {
                                    onSelect(
                                        selectedDay,
                                        selectedSlot,
                                        business?.booking_mode ? false : vipAppointment,
                                        false,
                                        res.data,
                                        res.data?.staff,
                                        res.data?.notAvailableStaff
                                    );
                                } else {
                                    setResponse('No staff available in your selected time');
                                }
                            } else if (res.data) {
                                onSelect(
                                    selectedDay,
                                    selectedSlot,
                                    business?.booking_mode ? false : vipAppointment,
                                    false,
                                    res.data
                                );
                            } else {
                                setResponse('No staff available in your selected time');
                            }
                        } else {
                            let arr1: any = res.data;

                            let finalSlots = arr1;
                            arr1?.sort();
                            setResponse('');

                            if (vipAppointment == false) {
                                setMorning([]);
                                setAfterNoon([]);
                                setEvening([]);
                                finalSlots?.map((time: any, index: any) => {
                                    if (currentDate == date) {
                                        if (time >= currentTime) {
                                            slotList(time);
                                        }
                                    } else {
                                        slotList(time);
                                    }
                                });
                            }
                        }
                    }
                })
                .catch((e: any) => {
                    setLoadig(false);
                    if (e?.response?.data?.message) {
                        setResponse(e?.response?.data?.message);
                    }
                });
        }
    };

    return (
        <>
            <div className="booking-wrapper">
                <div className="boking-widget--header">
                    <div className="booking-widget--backBtn" onClick={onPrevious}>
                        <img src={leftChevron} alt="" />
                    </div>
                    <h3>{t('Select Date and Time')}</h3>
                </div>
                <div className="booking-widget--body">
                    <Flex className="mini-calendar">
                        <DayPicker
                            mode="single"
                            disabled={{ before: today }}
                            selected={selectedDay}
                            onDayClick={day => {
                                setSelectedDay(day);
                                setSelectedSlot('');
                            }}
                        />
                        <div>
                            {serviceProvider?.vip == true && (
                                <VipAppointment>
                                    <CustomSpan>{t('Vip Appointment?')}</CustomSpan>
                                    <Switch
                                        value={vipAppointment}
                                        onChange={(val: any) => {
                                            setVipAppointment(val);
                                        }}
                                        isSmall={true}
                                    />{' '}
                                    <Info
                                        className="fa fas fa-info-circle"
                                        onClick={() => setInfoModel(true)}></Info>
                                </VipAppointment>
                            )}
                            <Divider />
                            {!vipAppointment && serviceProvider?.is_waiting && (
                                <>
                                    <VipAppointment>
                                        <CustomSpan>{t('In Green, available slots')}</CustomSpan>
                                        <div></div>
                                        <GreenBadge></GreenBadge>
                                    </VipAppointment>
                                    <VipAppointment>
                                        <CustomSpan>{t('In Black, waiting list slots')}</CustomSpan>
                                        <div></div>
                                        <BlackBox></BlackBox>
                                    </VipAppointment>
                                    <Divider />
                                </>
                            )}
                            {selectedDate !== null && (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        Date Selected: &nbsp;
                                        {moment(selectedDate).format('ddd, MMMM, DD, YYYY')}
                                    </div>
                                </>
                            )}
                            <div className="col-3" onClick={() => setInfoModel(false)}>
                                <div>
                                    <Text>{t('Morning')}</Text>
                                </div>
                                <div>
                                    <Text>{t('Afternoon')}</Text>
                                </div>
                                <div>
                                    <Text>{t('Evening')}</Text>
                                </div>
                            </div>
                            <div className="col-3">
                                <div>
                                    {morning.map((x, i) => {
                                        return (
                                            <div
                                                className={`timeSlot ${
                                                    selectedSlot === x ? 'selectedSlot' : ''
                                                }`}
                                                style={{
                                                    border:
                                                        serviceProvider?.is_waiting &&
                                                        appointmentSlots?.includes(x)
                                                            ? '1px solid rgb(9, 44, 76)'
                                                            : '',
                                                }}
                                                key={i}
                                                onClick={() => {
                                                    setSelectedSlot(x);
                                                    setResponse('');
                                                }}>
                                                {moment(
                                                    moment().format() + ' ' + x,
                                                    'YYYY-MM-DD HH:mm:ss'
                                                ).format('hh:mm a')}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    {afterNoon.map((x, i) => (
                                        <div
                                            className={`timeSlot ${
                                                selectedSlot === x ? 'selectedSlot' : ''
                                            }`}
                                            style={{
                                                border:
                                                    serviceProvider?.is_waiting &&
                                                    appointmentSlots?.includes(x)
                                                        ? '1px solid rgb(9, 44, 76)'
                                                        : '',
                                            }}
                                            key={i}
                                            onClick={() => {
                                                setSelectedSlot(x);
                                                setResponse('');
                                            }}>
                                            {moment(
                                                moment().format() + ' ' + x,
                                                'YYYY-MM-DD HH:mm:ss'
                                            ).format('hh:mm a')}
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    {evening.map((x, i) => (
                                        <div
                                            className={`timeSlot ${
                                                selectedSlot === x ? 'selectedSlot' : ''
                                            }`}
                                            style={{
                                                border:
                                                    serviceProvider?.is_waiting &&
                                                    appointmentSlots?.includes(x)
                                                        ? '1px solid rgb(9, 44, 76)'
                                                        : '',
                                            }}
                                            key={i}
                                            onClick={() => {
                                                setSelectedSlot(x);
                                                setResponse('');
                                            }}>
                                            {moment(
                                                moment().format() + ' ' + x,
                                                'YYYY-MM-DD HH:mm:ss'
                                            ).format('hh:mm a')}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-12">
                                {loading && (
                                    <ThreeCircles
                                        visible={true}
                                        height="70"
                                        width="70"
                                        color="#092C4C"
                                        ariaLabel="three-circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="loader-style"
                                    />
                                )}

                                {selectedDay ? (
                                    morning.length || afterNoon.length || evening.length ? (
                                        ''
                                    ) : !loading ? (
                                        <div className="date-empty-text">
                                            <span className="text">
                                                {response !== ''
                                                    ? response
                                                    : t(
                                                          'No slots available for the date you selected.'
                                                      )}
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    <div className="date-empty-text">
                                        {' '}
                                        <span className="text">{t('Please select a date.')}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Flex>
                </div>
                {selectedSlot ? (
                    <div
                        className="booking-widget--footer"
                        onClick={() => {
                            business?.booking_mode && selectedDay && selectedSlot
                                ? getFirstAvailableStaff(mydate, selectedSlot, true)
                                : serviceProvider?.is_waiting &&
                                  appointmentSlots?.includes(selectedSlot)
                                ? setWaitingList(true)
                                : onSelect(
                                      selectedDay,
                                      selectedSlot,
                                      business?.booking_mode ? false : vipAppointment,
                                      false
                                  );
                        }}
                        style={{
                            position: 'fixed',
                            flexDirection: 'column',
                            height: '7rem',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        {business?.booking_mode && response ? (
                            <div className="card_error" style={{ marginBottom: '0.5rem' }}>
                                {' '}
                                <span className="error-text">{response}</span>
                            </div>
                        ) : (
                            ''
                        )}
                        <button className="btn">{t('Next')}</button>
                    </div>
                ) : (
                    <div
                        className="booking-widget--footer-disabled"
                        style={{
                            position: 'fixed',
                            flexDirection: 'column',
                            height: '7rem',
                            display: selectedSlot ? 'flex' : 'none',
                            justifyContent: 'center',
                        }}>
                        {business?.booking_mode && response ? (
                            <div className="card_error" style={{ marginBottom: '0.5rem' }}>
                                {' '}
                                <span className="error-text">{response}</span>
                            </div>
                        ) : (
                            ''
                        )}
                        <button className="btn">{t('Next')}</button>
                    </div>
                )}
            </div>
            {infoModel === true && <InfoModel onClose={() => setInfoModel(false)} />}
            {waitingList && (
                <WaitingListConfirmationModel
                    onClose={() => setWaitingList(false)}
                    onSelect={() =>
                        onSelect(
                            selectedDay,
                            selectedSlot,
                            business?.booking_mode ? false : vipAppointment,
                            true
                        )
                    }
                />
            )}
        </>
    );
};

const Flex = styled.div`
    display: flex;
    padding-bottom: 1rem;
`;

const CustomSpan = styled.span`
    font-size: 1rem;
`;

const Text = styled.p`
    text-align: center;
`;

const VipAppointment = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const Info = styled.i`
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 0.2rem;
`;

const GreenBadge = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: green;
    border-radius: 50%;
    right: 0.2rem;
    bottom: 0.4rem;
`;

const BlackBox = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: rgb(9, 44, 76);
    border-radius: 50%;
    right: 0.2rem;
    bottom: 0.4rem;
`;

const Divider = styled.hr`
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    border: 0.1rem none none none solid rgb(9, 44, 76);
    border-top: none;
    border-right: none;
    border-left: none;
`;

export default AppointmentCalendar;
