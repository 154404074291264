/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Widget Component
 * Description: This component represents the booking widget for scheduling appointments.
 * It includes functionalities such as selecting services, professionals, date and time, customer details, redemption coupen and payment processing.
 * create an appointment with or without payment based on customer selection. Involves staff with a Stripe connection, creating a payment intent, initializing payment, creating a payment method, and finalizing payment after obtaining all necessary details.
 *
 */

import { useEffect, useState, useCallback } from 'react';
import SelectProfessional from './SelectProfessional';
import SelectService from './SelectService';
import * as SharedEventTypes from '../shared/eventTypes';
import { IBusiness } from 'interfaces/business.interface';
import { IService } from 'interfaces/service.interface';
import './styles.scss';
import AppointmentCalendar from './AppointmentCalendar';
import CompleteBooking from './CompleteBooking';
import CustomerDetails from './CustomerDetails';
import { publicApi } from '../../../helpers/public-axios';
import LoaderOverlay from '../../common/loaderOverlay/LoaderOverlay';
import { IServiceProvider } from '../../../interfaces/serviceProvider.interface';
import SuccessfulAppointment from './SuccessfulAppointment';
import SelectTip from './SelectTip';
import moment from 'moment-timezone';
import i18n from './i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../common/snackbar/useSnackbar';

const isEbookingApi = (event: any) => {
    return !!event && event.data && event.data.type && event.data.type in SharedEventTypes;
};

const Widget = () => {
    const { t }: any = useTranslation();
    const [step, setStep] = useState(0);
    const [business, setBusiness] = useState<IBusiness>();
    const [selectedService, setSelectedService] = useState<any>();
    const [selectedProfessional, setSelectedProfessional] = useState<any>();
    const [paymentIntentId, setPaymentIntentId] = useState<any>(null);
    const [paymentMethodId, setPaymentMethodId] = useState<any>(null);
    const [calendarDate, setCalendarDate] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [customerData, setCustomerData] = useState<any>();
    const [my_price, setMy_Price] = useState<any>();
    const [my_duration, setMy_Duration] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [gratuaty, setGratuaty] = useState<any>();
    const [total, setTotal] = useState<number>(0);
    const [tipLabel, setTipLabel] = useState<any>();
    const [customer_note, setCustomer_note] = useState<any>();
    const [onSuccess, setOnSuccess] = useState<boolean>(false);
    const [widgetModel, setWidgetModel] = useState<boolean>(true);
    const [reserve, setReserve] = useState<boolean>(false);
    const [is_vip, setIs_Vip] = useState<any>();
    const [vip_price, setVip_Price] = useState<any>();
    const [errorMessage, setErrorMessage] = useState('');
    const [redeemCoupen, setRedeemCoupen] = useState('');
    const [waitingList, setWaitingList] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const [discountMessage, setDiscountMessage] = useState('');
    const [isPackage, setIsPackage] = useState<any>(false);
    const [availableStaff, setAvailableStaff] = useState<any>();
    const [notAvailableStaff, setNotAvailableStaff] = useState<any>();

    useEffect(() => {
        window.addEventListener('message', receiveMessage, false);
    });

    useEffect(() => {
        if (paymentIntentId !== null && paymentMethodId?.id !== undefined) {
            completePayment();
        }
    }, [paymentMethodId?.id, paymentIntentId, business]);

    const receiveMessage = (event: any) => {
        if (isEbookingApi(event)) {
            switch (event.data.type) {
                case SharedEventTypes.INIT_IFRAME:
                    return getBusinessById(event.data.value.clientId);
                default:
                    return;
            }
        }
    };

    const getBusinessById = useCallback((businessId: string) => {
        publicApi.get(`/auth/public/${businessId}`).then((res: any) => {
            setBusiness(res.data.business);
            localStorage.setItem('access-token', JSON.stringify(res.data.token));
            i18n.changeLanguage(res.data.business?.language);
            localStorage.setItem('lang', res.data.business?.kanguage);
            moment.tz.setDefault(res.data.business.timezone);
        });
    }, []);

    let newAppointment: any = {
        id: null,
        intent_id: null,
        booking_without_payment: business?.booking_without_payment,
        stripe_account_verified:
            selectedProfessional?.stripe_account_verified == true
                ? selectedProfessional?.stripe_account_verified
                : business?.stripe_account_verified,
        startTime: startTime,
        booked_from_iso: calendarDate,
        booked_till_iso: calendarDate,
        booked_from: calendarDate,
        booked_till: calendarDate,
        customer: '',
        service: selectedService?._id,
        serviceProvider: selectedProfessional?._id,
        total: is_vip == true ? business?.currency + vip_price : business?.currency + my_price,
        total_value: (
            business?.booking_without_payment == true ||
            selectedProfessional?.stripe_account_verified
                ? selectedProfessional?.stripe_account_verified == false
                : business?.stripe_account_verified == false
        )
            ? is_vip == true
                ? vip_price
                : my_price
            : total
            ? total
            : my_price,
        duration: my_duration,
        platform: 'widget',
        customer_note: customer_note,
        is_vip: is_vip,
        is_waiting: waitingList,
        is_package: isPackage,
        package_id: selectedService?._id,
        availableStaff,
        notAvailableStaff,
    };

    // redeem coupon
    const couponUsage = () => {
        publicApi
            .post(`/gift-card/use-coupen`, { redeem: redeemCoupen })
            .then((res: any) => {
                console.log('success', res.data);
            })
            .catch((e: any) => {
                if (e?.response) {
                    const response = e?.response?.data?.message;
                    console.log('error', response?.message);
                }
            });
    };

    //create payment intent
    const createPaymentIntent = useCallback(
        (
            customerData: any,
            total: any,
            selectedProfessional: any,
            calendarDate: any,
            redeem?: any
        ) => {
            if (!business || !selectedService) return;
            let totalAmount: any = parseFloat(total) * 100;
            const payment_intent = {
                stripe_account_id:
                    selectedProfessional?.stripe_account_id !== null
                        ? selectedProfessional?.stripe_account_id
                        : business?.stripe_account_id,
                amount: parseInt(totalAmount),
                customer_details: customerData,
                description: {
                    service_name: selectedService.name,
                    staff_name: selectedProfessional?.name,
                    date: calendarDate,
                },
            };
            setLoading(true);
            publicApi
                .post('/billing/payment-intent', payment_intent)
                .then(res => {
                    const data = res.data as any;
                    setLoading(false);
                    setPaymentIntentId(data.payment_intent_id);
                    redeem === true && couponUsage();
                    setStep(5); // move to next step
                })
                .catch((e: any) => {
                    setLoading(false);
                    setOnSuccess(true);
                    if (e?.response) {
                        const response = e?.response?.data?.message;
                        console.log('error', response?.message);
                    }
                });
        },
        [business, selectedService]
    );

    const completePayment = () => {
        const services = {
            price: business?.currency + my_price,
            price_value: my_price,
            quantity: 1,
            service_name: selectedService!.name,
            service_id: selectedService!._id,
            tax_rate: selectedService!.tax_rate,
        };
        const transaction_details = {
            customer: '',
            total: business?.currency + my_price,
            total_value: total,
            items: services,
            payments: [
                {
                    amount: business?.currency + total.toFixed(2),
                    amount_value: total.toFixed(2),
                    payment_method: { code: 'paypal', label: 'Paypal' },
                    tip: gratuaty,
                },
            ],
            payment_method: { code: 'paypal', label: 'Paypal' },
            tip: {
                amount: business?.currency + parseFloat(gratuaty).toFixed(2),
                amount_value: parseFloat(gratuaty).toFixed(2),
                label: gratuaty == 0 ? 'no tip' : tipLabel,
            },
        };
        publicApi
            .post('/appointments/payment/pay', {
                stripe_account_id:
                    selectedProfessional?.stripe_account_id !== null
                        ? selectedProfessional?.stripe_account_id
                        : business?.stripe_account_id,
                payment_intent_id: paymentIntentId,
                payment_method_id: paymentMethodId?.id,
                appointment_details: newAppointment,
                customer_details: customerData,
                transaction_details: transaction_details,
            })
            .then((res: any) => {
                if (res.data && res.data.status == 'succeeded') {
                    if (res?.data?.discounted) {
                        setDiscountMessage(
                            t(`Congratulations! You've received a loyalty discount of`) +
                                res?.data?.discount +
                                '%'
                        );
                    } else {
                        setDiscountMessage('');
                    }
                    setSelectedProfessional('');
                    setLoading(false);
                    setPaymentIntentId(null);
                    setPaymentMethodId(null);
                    setStep(6);
                    setErrorMessage('');
                    setCalendarDate('');
                    setStartTime('');
                    setWaitingList(false);
                    setIs_Vip(false);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                setOnSuccess(true);
                if (e?.response) {
                    const response = e?.response?.data?.message;
                }
                if (e?.response) {
                    setErrorMessage(e?.response?.data?.message);
                }
            });
    };

    const appointment_without_Payment = (customer_details: any) => {
        setLoading(true);
        publicApi
            .post('/appointments/payment/pay', {
                stripe_account_id:
                    selectedProfessional?.stripe_account_id !== null
                        ? selectedProfessional?.stripe_account_id
                        : business?.stripe_account_id,
                payment_intent_id: paymentIntentId,
                payment_method_id: paymentMethodId?.id,
                appointment_details: newAppointment,
                customer_details: customer_details,
                transaction_details: '',
            })
            .then((res: any) => {
                if (res?.data) {
                    if (res?.data?.discounted) {
                        setDiscountMessage(
                            t(`Congratulations! You've received a loyalty discount of`) +
                                res?.data?.discount +
                                '%'
                        );
                    } else {
                        setDiscountMessage('');
                    }
                    setSelectedProfessional('');
                    setLoading(false);
                    setErrorMessage('');
                    setCalendarDate('');
                    setStartTime('');
                    setWaitingList(false);
                    setIs_Vip(false);
                    if (
                        business?.booking_without_payment == true ||
                        selectedProfessional?.stripe_account_verified == false ||
                        business?.stripe_account_verified == false
                    ) {
                        setPaymentIntentId(null);
                        setPaymentMethodId(null);
                        setStep(6);
                    }
                    openSnackbar('successfully');
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data?.message;
                    setErrorMessage(response);
                    openSnackbar(response);
                }
            });
    };

    const reserve_Without_Payment = (customer_details: any, paymentMethodId: any) => {
        setReserve(true);
        publicApi
            .post('/appointments/payment/pay', {
                stripe_account_id:
                    selectedProfessional?.stripe_account_id !== null
                        ? selectedProfessional?.stripe_account_id
                        : business?.stripe_account_id,
                payment_intent_id: paymentIntentId,
                payment_method_id: paymentMethodId,
                appointment_details: newAppointment,
                customer_details: customer_details,
                transaction_details: { reserve_Without_Payment: true },
            })
            .then((res: any) => {
                if (res?.data) {
                    if (res?.data?.discounted) {
                        setDiscountMessage(
                            t(`Congratulations! You've received a loyalty discount of`) +
                                res?.data?.discount +
                                '%'
                        );
                    } else {
                        setDiscountMessage('');
                    }
                    setReserve(false);
                    setPaymentIntentId(null);
                    setPaymentMethodId(null);
                    setStep(6);
                    setErrorMessage('');
                }
            })
            .catch((e: any) => {
                setReserve(false);
                if (e?.response) {
                    const response = e?.response?.data?.message;
                }
                if (e?.response) {
                    setErrorMessage(e?.response?.data?.message);
                }
            });
    };

    const toggleWidgetModel = () => {
        setWidgetModel(true);
        setStep(0);
        const message = widgetModel == true ? 'openModel' : 'closeModel';
        window.parent.postMessage(message, '*');
        if (widgetModel == true) {
            document?.getElementById('widget')?.classList.add('on');
            document?.getElementById('widget')?.classList.remove('off');
        } else {
            document?.getElementById('widget')?.classList.remove('on');
            document?.getElementById('widget')?.classList.add('off');
        }
    };

    return (
        <>
            {widgetModel && (
                <div id="booking-overlay">
                    <div className="booking-widget booking-widget-wrapper">
                        <div className="closeWidget" onClick={toggleWidgetModel}></div>
                        {business && business._id ? (
                            <>
                                {step === 0 && (
                                    <SelectService
                                        businessId={business}
                                        close={toggleWidgetModel}
                                        onSelect={(service: IService, is_package?: boolean) => {
                                            setSelectedService(service);
                                            setIsPackage(is_package);
                                            setStep(prevVal =>
                                                business?.booking_mode
                                                    ? prevVal + 2
                                                    : is_package
                                                    ? prevVal + 2
                                                    : prevVal + 1
                                            );
                                        }}
                                    />
                                )}
                                {step === 1 && (
                                    <SelectProfessional
                                        my_duration={myDuration => setMy_Duration(myDuration)}
                                        my_price={myPrice => setMy_Price(myPrice)}
                                        vip_price={vip => setVip_Price(vip)}
                                        onPrevious={() => {
                                            setStep(prevVal => prevVal - 1);
                                        }}
                                        onSelect={(professional: IServiceProvider) => {
                                            setSelectedProfessional(professional);
                                            setStep(prevVal => prevVal + 1);
                                        }}
                                        serviceId={selectedService?._id}
                                        businessId={business}
                                    />
                                )}
                                {step === 2 && (
                                    <AppointmentCalendar
                                        timezone={business && business?.timezone}
                                        onPrevious={() => {
                                            setErrorMessage('');
                                            setStep(prevVal =>
                                                business?.booking_mode
                                                    ? prevVal - 2
                                                    : isPackage
                                                    ? prevVal + 2
                                                    : prevVal - 1
                                            );
                                        }}
                                        business={business}
                                        serviceProvider={selectedProfessional}
                                        serviceDuration={my_duration}
                                        defaultData={{
                                            selectedDay: calendarDate ? new Date(calendarDate) : '',
                                            startTime: startTime ? startTime : '',
                                            is_vip: is_vip ? is_vip : false,
                                            is_waiting: waitingList ? waitingList : false,
                                        }}
                                        isPackage={isPackage}
                                        onSelect={(
                                            selectedDay: any,
                                            startTime: any,
                                            is_vip: any,
                                            is_waiting: any,
                                            serviceProvider: any,
                                            availableStaff?: any,
                                            notAvailableStaff?: any
                                        ) => {
                                            let selct: any = new Date(selectedDay);
                                            let month: any = parseInt(selct.getMonth() + 1);
                                            let day: any = selct.getDate();
                                            if (month < 10) {
                                                month = '0' + month;
                                            }
                                            if (day < 10) {
                                                day = '0' + day;
                                            }
                                            let mydate =
                                                selct.getFullYear() + '-' + month + '-' + day;
                                            setCalendarDate(mydate);
                                            setStartTime(startTime);
                                            setIs_Vip(is_vip);
                                            setStep(prevVal => prevVal + 1);
                                            setWaitingList(is_waiting);
                                            if (serviceProvider) {
                                                if (serviceProvider?.services?.length) {
                                                    setMy_Duration(
                                                        serviceProvider?.services[0]?.my_duration
                                                    );
                                                    setMy_Price(
                                                        serviceProvider?.services[0]?.my_price
                                                    );
                                                }

                                                setSelectedProfessional(serviceProvider);
                                            }
                                            setAvailableStaff(availableStaff);
                                            setNotAvailableStaff(notAvailableStaff);
                                        }}
                                        selectedService={selectedService}
                                    />
                                )}
                                {step === 3 && (
                                    <CustomerDetails
                                        buttonTitle={
                                            business?.booking_without_payment == true ||
                                            (selectedProfessional?.stripe_account_verified
                                                ? selectedProfessional?.stripe_account_verified ==
                                                  false
                                                : business?.stripe_account_verified == false)
                                                ? t('Reserve')
                                                : loading == false
                                                ? t('Next')
                                                : t('Please wait...')
                                        }
                                        onPrevious={() => {
                                            setStep(prevVal => prevVal - 1);
                                            setErrorMessage('');
                                        }}
                                        errorMessage={errorMessage}
                                        customer_note={val => {
                                            setCustomer_note(val);
                                        }}
                                        loading={loading}
                                        onNext={(customer_details: any) => {
                                            if (
                                                business?.booking_without_payment == true ||
                                                selectedProfessional?.stripe_account_verified
                                                    ? selectedProfessional?.stripe_account_verified ==
                                                      false
                                                    : business?.stripe_account_verified == false
                                            ) {
                                                appointment_without_Payment(customer_details);
                                            } else {
                                                setCustomerData(customer_details);
                                                setStep(4);
                                            }
                                        }}
                                    />
                                )}
                                {step === 4 && (
                                    <SelectTip
                                        onPrevious={() => {
                                            setStep(prevVal => prevVal - 1);
                                            setErrorMessage('');
                                        }}
                                        loading={loading}
                                        subTotal={is_vip == true ? vip_price : my_price}
                                        businessId={business}
                                        service={selectedService?.name}
                                        tipLabel={val => setTipLabel(val)}
                                        buttonTitle={
                                            loading == false ? t('Next') : t('Please wait...')
                                        }
                                        gratuity={(value: any, totalCharges: any) => {
                                            setGratuaty(value);
                                            setTotal(totalCharges);
                                        }}
                                        onSuccess={onSuccess}
                                        couponUsed={(e: any) => setRedeemCoupen(e)}
                                        onSelect={e => {
                                            if (e !== '') {
                                                createPaymentIntent(
                                                    customerData,
                                                    total,
                                                    selectedProfessional,
                                                    calendarDate,
                                                    true
                                                );
                                            } else {
                                                createPaymentIntent(
                                                    customerData,
                                                    total,
                                                    selectedProfessional,
                                                    calendarDate
                                                );
                                            }
                                        }}
                                    />
                                )}
                                {step === 5 && business && selectedService && (
                                    <CompleteBooking
                                        businessId={business}
                                        reserve={reserve}
                                        errorMessage={errorMessage}
                                        loading={onSuccess}
                                        service={total ? total : my_price}
                                        buttonTitle={`${selectedService!.name} ${t('with')} ${
                                            selectedProfessional?.name
                                        } on ${moment(calendarDate).format(
                                            'MMMM DD, YYYY'
                                        )} at ${moment(
                                            moment().format() + ' ' + startTime,
                                            'YYYY-MM-DD HH:mm:ss'
                                        ).format('hh:mm a')}`}
                                        stripeAccountId={
                                            selectedProfessional?.stripe_account_id !== null
                                                ? selectedProfessional?.stripe_account_id
                                                : business?.stripe_account_id
                                        }
                                        onPrevious={() => {
                                            setStep(prevVal => prevVal - 1);
                                            setErrorMessage('');
                                        }}
                                        onNext={(paymentMethodId: string) => {
                                            setPaymentMethodId(paymentMethodId);
                                        }}
                                        customer={customerData.email}
                                        onReserve={(id: any) => {
                                            if (id !== undefined) {
                                                reserve_Without_Payment(customerData, id);
                                            }
                                        }}
                                    />
                                )}
                                {step === 6 && (
                                    <SuccessfulAppointment
                                        business={business}
                                        message={discountMessage}
                                        onBack={() => setStep(0)}
                                    />
                                )}
                            </>
                        ) : (
                            <LoaderOverlay />
                        )}

                        {business && business.booking_widget && (
                            <style>{`
                .btn {
                    background-color: ${business.booking_widget.button_bg_color};
                    color: ${business.booking_widget.button_text_color};
                },
            `}</style>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Widget;
