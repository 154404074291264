/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Common defined colour used in app.
 * defined default font sizes also to use commenly in app.
 */

export const palette = {
    primary: '#eb5353', // delete red
    secondary: '#092C4C', // buttons
    success: '#14a88b',
    black: '#000',
    white: '#fff',
    dark: '#27292e ', // normal text
    light: '#ffffff',
    silver: '#7d8083', // para text color or icon
    gold: '#B99F57',
    teal: '#00E2BA',
    green: '#00be70', //price tags
    freshGreen: '#16a34a',
    hover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #092C4C;',
    header: 'linear-gradient(350.99deg, #333333 -62.86%, #092c4c 125.32%)',
    overlay: '#00000080', // models side overlay
    grey: {
        lightest: '#e5e7eb', //border
        light: '#f4f5f6', //page gray
        shadow: '#523f690d', //card shadow
        shadowDarkest: '#00000047', // + button shadow
        dark: '#636363',
        darkest: '#232323',
    },
    blue: {
        inkBlue: '#4f45e4',
    },
};
export const fonts = {
    xsmall: '0.8rem',
    small: '1rem',
    regular: '1.2rem',
    medium: '1.8rem',
    large: '2.2rem',
    xlarge: '3.0rem',
    xxlarge: '4rem',
};
