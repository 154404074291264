/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SuccessfulAppointment Component
 * Description: This component displays a success message for a booked appointment and provides a button to book another appointment.
 *
 * Props:
 * - onBack: () => void - Callback function triggered when the "Book Another Appointment" button is clicked.
 * - business: object - Information about the business.
 * - message: string - Custom success message for the booked appointment.
 */

import { useTranslation } from 'react-i18next';

const SuccessfulAppointment = ({
    onBack,
    business,
    message,
}: {
    onBack?(): void;
    business: any;
    message?: any;
}) => {
    const { t }: any = useTranslation();

    return (
        <div className="booking-widget--body" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="successful--appointment-container">
                <div className="seccessful--title">
                    {message ? message : t('Appointment successfully booked.')}
                </div>
                <div className="bookAgain--button" onClick={onBack}>
                    <button
                        className="btn-book"
                        style={{
                            width: 'auto',
                            backgroundColor: `${
                                business?.booking_widget?.button_bg_color
                                    ? business?.booking_widget?.button_bg_color
                                    : ''
                            }`,
                            color: `
                    ${
                        business?.booking_widget?.button_text_color
                            ? business?.booking_widget?.button_text_color
                            : 'white'
                    }`,
                        }}>
                        {t('Book Another Appointment')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SuccessfulAppointment;
